<template>
  <div class="duihua">
    <div class="page-header">
      <p>
        <strong>兑换记录</strong>
      </p>
    </div>
    <div class="duihuan">
      <!-- 右边的兑换记录 -->
      <div class="duihuans">
        <div class="dui">
          <div class="duihuan-all">
            <div class="duihuan-alls">
              <ul>
                <li
                    v-for="(orderNavItem, OrderNavIndex) in orderNav"
                    :key="OrderNavIndex"
                    :class="'duihuan-alls2' + orderNavItem.isActive"
                    @click="changeOrderStatus(orderNavItem)"
                    @mouseenter="showRed(orderNavItem)"
                    @mouseleave="leaveGray(orderNavItem)"
                >

                  <el-badge :value="orderNavItem.count" class="item">
                    {{ orderNavItem.name }}
                  </el-badge>
                </li>
              </ul>
            </div>
            <div class="duihuan-all-shop-box">
              <el-table
                  v-loading="orderLoading"
                  :data="orders"
                  height="790"
                  empty-text="暂无兑换记录"
                  border style="width: 100%;"
                  :header-cell-style="{
                    'text-align': 'center',
                    height: '36px',
                    color: '#000',
                    'font-size': '14px',
                    background: '#f4f4f4',
                  }"
                  :cell-style="{
                    'text-align': 'center',
                    height: '50px',
                    color: '#575757',
                    'font-size': '12px',
                  }"
                  stripe>
                <el-table-column prop="sn" label="订单号" width="120"/>
                <el-table-column prop="goods_thumb" label="商品" width="200">
                  <template slot-scope="scope">
                    <img class="goods-thumb" v-lazy="scope.row.goods_thumb[0]" alt=""/>
                    <p v-for="(nameItem, nameIndex) in scope.row.goods_name" :key="nameIndex">
                      {{ nameItem }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="单价">
                  <template slot-scope="scope">
                    {{ scope.row.price[0] }} 爪币
                  </template>
                </el-table-column>
                <el-table-column prop="count" label="数量">
                  <template slot-scope="scope">
                    x {{ scope.row.count }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="实付款">
                  <template slot-scope="scope">
                    {{ scope.row.need_pay_amount }} 爪币 <br/>
                    含运费：{{ scope.row.shipping_fee }} 爪币
                  </template>
                </el-table-column>
                <el-table-column prop="is_default" label="交易状态">
                  <template slot-scope="scope">
                    {{ scope.row.status_name }}
                    <br/>
                    <el-link type="primary" style="font-size:12px" @click="toInfo( scope.row.id )">
                      查看详情
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="is_default" label="交易操作" fixed="right" width="160">
                  <template slot-scope="scope">
                    <!--                    <el-link type="danger" icon="el-icon-delete" style="font-size:12px" @click="removeOrder(scope.row.id)">-->
                    <!--                      删除-->
                    <!--                    </el-link>-->
                    <!--                    <br />-->
                    <div v-if="scope.row.status == 10">
                      <p style="display: flex; align-items: center; justify-content: center">
                        <Isvg icon-class="mall-timedown"/>
                        {{ scope.row.expired_time }}
                      </p>
                      <el-button class="order-button" size="mini" type="primary"> 立即支付</el-button>
                    </div>
                    <div v-if="scope.row.status == 20 || scope.row.status == 25">
                      <el-button class="order-button" type="danger" size="mini" @click="applyRetreatGoods(scope.row.id)">退款/退货</el-button>
                      <br/>
                      <el-button class="order-button" size="mini" type="primary" @click="confimGoods(scope.row.id)">
                        确认收货
                      </el-button>
                      <el-button class="order-button" type="warning" size="mini" v-if="scope.row.status == 25"> 拒收</el-button>
                    </div>
                    <div v-if="scope.row.status == 30">
                      <el-button class="order-button" size="mini" type="primary"
                                 @click="applyRetreatGoods(scope.row.id)"> 申请退货
                      </el-button>
                    </div>
                    <div v-if="scope.row.status == 90">
                      <el-button class="order-button" size="mini" type="danger"> 退款/退货</el-button>
                    </div>
                    <div v-if="scope.row.status == 90">
                      <el-button class="order-button" size="mini" type="info"> 交易已关闭</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </div>
          </div>
        </div>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pages.page"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="pages.page_size"
              layout="prev, pager, next,->,total,jumper"
              :total="pages.total"
              background/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {orderList, orderDelete, orderStatusCount, confimOrderGoods} from "@/api/goods";

export default {
  data() {
    return {
      item: true,
      items: false,

      orderNav: [
        {isActive: " active bind", name: "全部", key: "all", count: ""},
        {isActive: "", name: "待付款", key: "wait_pay", count: ""},
        {isActive: "", name: "待收货", key: "wait_collect", count: ""},
        {isActive: "", name: "已完成", key: "success", count: ""},
        {isActive: "", name: "退货/退款", key: "wait_pay", count: ""},
      ],

      orders: [],
      pageParams: {page: 1, page_size: 10},
      pages: {page: 1, page_size: 10, total: 1},

      orderLoading: false,
      /* el-Tab */
      activeName: "second",
    };
  },
  created() {
    this.loadOrderStatus();
    this.loadOrderList(this.pageParams);
  },
  methods: {
    async loadOrderList(data) {
      this.orderLoading = true;
      const res = await orderList(data);
      if (res.statusCode == 200) {
        this.orders = res.data.data;
        this.pages.page = parseInt(res.data.pages.page);
        this.pages.page_size = parseInt(res.data.pages.page_size);
        this.pages.total = parseInt(res.data.pages.total);
        this.orderLoading = false;
      }
    },
    async loadOrderStatus() {
      const res = await orderStatusCount({});
      if (res.statusCode == 200) {
        this.orderNav.forEach((element) => {
          element.count =
              res.data[element.key] > 0 ? res.data[element.key] : "";
        });
      }
    },
    toInfo(orderId) {
      this.$notify({
        type: "success",
        message: "正在前往详情请稍后...",
      });
      setTimeout(() => {
        this.$router.push({path: "/home/coin/info", query: {order_id: orderId}});
      }, 1000);
    },
    removeOrder(orderId) {
      this.$confirm("此操作将删除此纪录！, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.toDelete({order_id: orderId});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
    },
    async toDelete(data) {
      const res = await orderDelete(data);
      if (res.statusCode == 200) {
        this.$message.success("操作成功");
        this.loadOrderList();
      } else {
        this.$message.error(res.message);
      }
    },
    async toConfim(data) {
      const res = await confimOrderGoods(data)
      if (res.statusCode == 200) {
        this.$notify({
          type: "success",
          message: "操作成功",
        });
        this.loadOrderList();
      } else {
        this.$message.error(res.message);
      }
    },
    changeOrderStatus(item) {
      this.orderNav.forEach(element => {
        element.isActive = ''
      })
      item.isActive = ' active bind'
      let data = {
        page: 1,
        page_size: this.pageParams.page_size,
        status: "all",
      };
      switch (item.key) {
        case "wait_pay":
          data.status = 10;
          break;
        case "wait_collect":
          data.status = [20, 25];
          break;
        case "success":
          data.status = [30, 90];
          break;
        case "after":
          data.status = [35, 40, 41, 42];
          break;
      }
      this.loadOrderList(data);
    },
    showRed(e) {
      if (e.isActive.indexOf('bind') == -1) {
        e.isActive = ' active'
      }
    },
    leaveGray(e) {
      if (e.isActive.indexOf('bind') == -1) {
        e.isActive = ''
      }
    },
    handleSizeChange(val) {
      this.pageParams.page_size = val;
      this.loadOrderList(this.pageParams);
    },
    handleCurrentChange(val) {
      this.pageParams.page = val;
      this.loadOrderList(this.pageParams);
    },
    confimGoods(orderId) {
      this.$confirm("请先确定您已收到货品，确认后只能退货，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.toConfim({order_id: orderId})
          })
          .catch(() => {
          });
    },
    applyRetreatGoods(orderId) {
      this.$confirm("您确定要申请退货吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
          })
          .catch(() => {
          });
    }
  },
};
</script>

<style lang="less" scoped>
.goods-thumb {
  width: 100px;
  height: 100px;
}

.order-button {
  width: 120px;
  margin-bottom: 10px;
  margin-left: unset;
}
/deep/.el-button--primary{
  background-color: #00ace6;
  border-color: #00ace6;
}

/deep/ .el-badge__content {
  background-color: #e51087;
}

.duihua {
  background-color: #fff;
  min-height: 930px;
  //padding: 10px 20px;
}

.page-header {
  height: 76px;
  border-bottom: 1px solid #b5b5b5;
  display: flex;
  background-color: #fff;

  p {
    font-size: 18px;
    margin: 40px;
  }
}

.all-banner img {
  width: 100%;
  height: 224px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.duihuan {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .duihuans {
    width: 100%;

    .duihuan-jilu {
      line-height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: #474747;
      margin-bottom: 10px;
    }

    .duihuan-alls {
      margin: 0 auto;
      width: 100%;

      span {
        color: #e4007f;
      }
    }

    .duihuan-alls > ul {
      display: flex;
      justify-content: center;

      li {
        height: 60px;
        line-height: 60px;
        width: 200px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #b5b5b5;
      }

      .duihuan-alls1 {
        width: 150px;
        text-indent: 17px;

        svg {
          width: 16px;
          line-height: 16px;
          position: relative;
          top: 3px;
          right: -35px;
        }
      }

      .duihuan-alls2.active {
        color: #e51087;
        border-bottom-color: #e4007f;
      }
    }

    .duihuan-all-shop-box {
      margin: 0 auto;
      overflow: auto;
      .duihuan-all-shop {
        width: 860px;
        height: 36px;
        background: #efefef;

        ul {
          display: flex;
          height: 36px;
          background-color: #00a2e6;
          text-align: center;
          align-items: center;

          li {
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            text-align: center;
          }

          .duihuan-all-shop1 {
            border-right: 1px solid #fff;
            width: 280px;
          }

          .duihuan-all-shop2 {
            width: 100px;
            border-right: 1px solid #fff;
          }

          .duihuan-all-shop3 {
            width: 80px;
            border-right: 1px solid #fff;
          }

          .duihuan-all-shop4 {
            width: 150px;
            border-right: 1px solid #fff;
          }

          .duihuan-all-shop5 {
            width: 100px;
            border-right: 1px solid #fff;
          }

          .duihuan-all-shop6 {
            width: 150px;
          }
        }
      }

      .duihuan-go-home {
        width: 240px;
        margin: 0 auto;
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        font-size: 14px;

        .duihuan-go-home-img {
          width: 240px;
          font-size: 64px;
        }

        .duihuan-go-home-font {
          width: 100%;
          margin-top: 20px;
        }

        .duihuan-go-home-button > button {
          width: 240px;
          height: 36px;
          background: #00a2e6;
          border: none;
          cursor: pointer;
          margin-top: 41px;
          font-size: 18px;
          margin-bottom: 60px;
        }
      }
    }
  }

  .duihuanjilu1 {
    margin-top: 10px;
    width: 860px;
    height: 115px;
    border-bottom: 1px solid #b5b5b6;
    margin-bottom: 10px;

    .duihuanjilu1-top {
      width: 860px;
      line-height: 24px;
      background: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .duihuanjilu1-top-left {
        margin-left: 10px;
        font-size: 12px;
        color: #474747;

        strong {
          font-weight: 500;
        }

        span {
          font-weight: 400;
          margin-right: 40px;
        }
      }

      .duihuanjilu1-top-right {
        img {
          width: 14px;
          height: 14px;
        }

        margin-right: 10px;
      }
    }

    .duihuanjilu1-bottom {
      width: 860px;
      display: flex;
      margin-top: 10px;
      font-size: 14px;
      color: #575757;
      align-content: space-between;

      .duihuajilu1-bottom-img {
        img {
          width: 70px;
          height: 70px;
          margin-right: 10px;
        }
      }

      .duihuajilu1-bottom-sp {
        width: 196px;
        font-size: 14px;
        font-weight: 400;
        color: #474747;

        p {
          line-height: 10px;
        }
      }

      .duihuajilu1-bottom-dj {
        width: 100px;
        text-align: center;
      }

      .duihuajilu1-bottom-sl {
        width: 80px;
        text-align: center;
      }

      .duihuajilu1-bottom-sfk {
        width: 150px;
        text-align: center;

        .duihuajilu1-bottom-sfk-hyf {
          margin-top: 10px;
          color: #595757;
        }
      }

      .duihuajilu1-bottom-jyzt {
        text-align: center;
        width: 100px;
        font-size: 14px;
        font-weight: 400;

        & > ul > li:first-of-type {
          color: #e51087;
        }

        & > ul > li:last-of-type {
          color: #595757;
          margin-top: 10px;
        }
      }

      .duihuajilu1-bottom-jycz {
        width: 150px;
        text-align: center;

        li > img {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }

        li > span {
          color: #474747;
          font-size: 12px;
          cursor: pointer;
        }

        li > button {
          width: 100px;
          line-height: 24px;
          background: #00a2e6;
          border: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }

        .duihuajilu1-bottom-jycz-button {
          margin: 6px 0;

          button {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }

        & > ul > li:last-of-type {
          color: #595757;
        }
      }
    }
  }

  .duihuan-right {
    width: 940px;
    box-sizing: border-box;

    .duihuan-right-top {
      width: 100%;
      line-height: 36px;
      font-size: 18px;
      color: #474747;
      margin-bottom: 10px;
    }

    .duihuan-right-dingdan {
      width: 940px;
      height: 260px;
      border: 1px solid #b5b5b6;
      margin-bottom: 20px;

      .duihuan-right-dingdan-one {
        width: 100%;
        height: 134px;
        border-bottom: 1px solid #b5b5b6;

        .duihuan-right-dingdan-one-detail {
          line-height: 32px;
          background: #efefef;

          span {
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #474747;
          }
        }

        .duihuan-right-dingdan-one-bianhao {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 14px;

          .duihuan-right-dingdan-one-bianhao1 {
            margin-left: 20px;
            height: 62px;
            color: #474747;
            display: flex;

            .duihuan-right-dingdan-one-bianhao1-font {
              margin-right: 6px;
            }

            span {
              margin-left: 10px;
              font-size: 12px;
              color: #595757;
            }

            li {
              margin-bottom: 10px;
            }
          }

          .duihuan-right-dingdan-one-bianhao2 {
            width: 100px;
            text-align: center;
            margin-right: -295px;

            & ul li:first-of-type {
              margin-bottom: 6px;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 11px;
            }
          }

          .duihuan-right-dingdan-one-bianhao3 {
            margin-right: 52px;

            ul li {
              margin-bottom: 10px;

              a {
                font-size: 14px;
                color: #595957;
              }
            }

            ul li:last-of-type {
              position: relative;
              left: 3px;
            }
          }
        }
      }

      .duihuan-right-dingdan-two {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .duihuan-right-dingdan-two-left {
          margin-left: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #474747;
          width: 460px;
          border-right: 1px solid #b5b5b6;
          height: 85px;
          display: flex;

          .duihuan-right-dingdan-two-left1 {
            text-align: right;
          }

          .duihuan-right-dingdan-two-left2 {
            width: 196px;
          }

          .duihuan-right-dingdan-two-left2 li:last-of-type {
            line-height: 24px;
            position: relative;
            top: -5px;
          }

          li {
            margin-bottom: 10px;
          }
        }

        .duihuan-right-dingdan-two-right {
          font-size: 14px;
          font-weight: 400;
          color: #474747;
          margin-right: 215px;
          display: flex;

          li {
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
    }

    .duihuan-right-wuliu {
      width: 940px;
      height: 132px;
      border: 1px solid #b5b5b6;
      margin-bottom: 20px;

      .duihuan-right-wuliu-top {
        width: 100%;
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #474747;
        margin-bottom: 20px;
        background-color: #efefef;

        span {
          margin-left: 20px;
        }
      }

      .duihuan-right-wuliu-number {
        display: flex;
        margin: 20px 0 0 20px;

        .duihuan-right-wuliu-one {
          li {
            padding-right: 40px;
            border-right: 1px solid #b5b5b6;
            font-size: 14px;
            margin-bottom: 10px;
          }
        }

        .duihuan-right-wuliu-three {
          font-size: 14px;
          color: #474747;
          margin-left: 35px;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }

    .duihuan-right-shangpin {
      width: 940px;
      height: 247px;
      border: 1px solid #b5b5b6;

      .duihuan-right-shangpin-top {
        width: 940px;
        line-height: 32px;
        background: #efefef;

        ul {
          display: flex;

          li {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #575757;
            text-align: center;
          }

          .duihuan-right-shangpin-top-sp {
            width: 400px;
          }

          .duihuan-right-shangpin-top-dj {
            width: 180px;
          }

          .duihuan-right-shangpin-top-sl {
            width: 180px;
          }

          .duihuan-right-shangpin-top-sf {
            width: 180px;
          }
        }
      }

      .duihuan-right-shangpin-bottom {
        width: 940px;
        display: flex;
        justify-content: space-between;
        height: 105px;
        border-bottom: 1px solid #b5b5b6;

        ul {
          display: flex;
          margin-top: 14px;

          li {
            font-size: 14px;
            font-weight: 400;
            color: #595757;

            img {
              width: 70px;
              height: 70px;
            }
          }

          & > li:nth-of-type(1) {
            margin: 0px 10px 0px 20px;
          }

          & > li:nth-of-type(2) {
            width: 266px;

            p {
              display: block;
              width: 266px;
              height: 38px;
              margin-bottom: 20px;
              line-height: 20px;
            }
          }

          & > li:nth-of-type(3) {
            margin: 0px 144px 0px 97px;
          }

          & > li:nth-of-type(5) {
            margin-left: 145px;
          }
        }
      }
    }

    .duihuan-right-shangpin-right {
      display: flex;
      justify-content: flex-end;

      ul {
        margin-top: 20px;

        li {
          font-size: 14px;
          color: #474747;
          margin-bottom: 10px;
        }
      }

      .duihuan-right-shangpin-right1 {
        text-align: right;
      }

      .duihuan-right-shangpin-right2 {
        margin: 20px 60px 0 6px;
      }
    }
  }
}

/* 分页器 */
.block {
  width: 300px;
  margin: 10px auto;
}

/deep/ .el-tabs__nav-scroll {
  height: 40px;
  width: 860px;
  margin: 0 auto;
}

/deep/ .monitorTop .el-tabs__item {
  font-size: 14px !important;
}

/deep/ .el-badge__content.is-fixed {
  transform: translateY(-10%) translateX(100%);
  right: -10px;
}
</style>
